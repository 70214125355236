<template>
  <div>
    <admin-header v-if="training" :title="training.name" :links="bcLinks"></admin-header>
    <v-card v-if="training" width="70%" min-height="50vh" max-height="50vh" class="mt-4">
      <v-card-title class="title font-weight-regular textDark--text">
        Syllabus
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <template v-for="course in training.courses">
          <v-card hover width="70%" :key="course._id" @click="courseClicked(course)">
            <v-card-title class="primary--text text-capitalize">
              <div class="d-flex justify-space-between align-center full-width">
                <span class="subtitle-1">{{ course.name }}</span>
                <span v-if="course.level !== 'None'" class="subtitle-1 secondaryText--text">{{ course.level }}</span>
              </div>
            </v-card-title>
            <v-card-text class="mt-n4">
              <div>{{ course.description }}</div>
              <v-chip v-if="course.submissionStepIds && course.submissionStepIds.length" class="mt-2" small color="textOrange" dark>Submission Required</v-chip>
            </v-card-text>
          </v-card>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import AdminHeader from "@/beez-admin/components/AdminHeader";

export default {
  name: "user-courses",
  data() {
    return {
      training: null,
      courses: [],
      loading: false,
      accountid: "",
      trainingId: null,
      courseData: null,
      headerTitle: ""
    };
  },
  created() {
    this.refresh();
  },
  components: {
    AdminHeader
  },
  computed: {
    ...mapFields("userElevation",["selectedTraining"]),
    bcLinks() {
      return [
        {
          text: "Trainings",
          disabled: false,
          to: {
            name: "user-training"
          }
        },
        {
          text: this.training.name,
          disabled: true
        }
      ];
    }
  },
  methods: {
    ...mapActions("userElevation", {
      getTraining: "getTraining",
      setSelectedCourse: "setSelectedCourse"
    }),
    courseClicked(course){

      this.setSelectedCourse(course);

      this.$router.push({
        name: 'user-course-info',
        params: {
          id: course._id
        }
      });
    },
    async refresh() {

      const trainingId = this.$route.params.trainingId;
      
      if (this.selectedTraining && this.selectedTraining._id === trainingId){
        this.training = this.selectedTraining;
        return;
      } 

      try {
        this.loading = true;
        const training = await this.getTraining(trainingId);
        this.training = training;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
